export default function maskaContato() {
    const contatoFormSubmit = document.querySelector('#form-submit')

    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function (event) {
        event.preventDefault();
        disabledBtn()

        let nome = document.querySelector('#nome').value;
        let email = document.querySelector('#telefone').value;
        let telefone = document.querySelector('#email_input').value;

        if (nome && telefone) {
            let mensagem = `Olá, meu nome é ${nome} e tenho dúvidas sobre o Maska`;
            let numeroWhatsApp = "+5521979485552"; // Substitua pelo seu número no formato internacional
            let url = `https://api.whatsapp.com/send?phone=${numeroWhatsApp}&text=${encodeURIComponent(mensagem)}`;

            const form_data = {
                //empresa: document.querySelector('#empresa').value,
                //produto: document.querySelector('#produto').value,
                name: document.querySelector('#nome').value,
                //telefone: document.querySelector('#telefone').value,
                phone: document.querySelector('#telefone').value,
                email: document.querySelector('#email_input').value,
                //whatsapp: document.querySelector('#whatsapp_input').value,
            }
            const url_form = "/criar-lead/"
            axios.post(url_form, form_data)
                .then((response) => showMessage(response, url))
                .catch((response) => showMessage(response));
        }else{
            alert("Por favor, preencha pelo menos o nome e o telefone.");
        }
    });




    function showMessage(response, url = undefined) {
        let message = response.data ? response.data.message : response.message
        if(!message){
            message = 'Resposta enviada com sucesso'
        }

        GLOBAL.showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(()=>{

            enabledBtn();
            if(url != undefined){
                window.open(url, '_blank');
            }
        }, 2000)
    }

    const btnSubmit = document.querySelector(".form-send-mensage .btn-contato")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.style.opacity = "0.5"
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.style.opacity = "1.0"
    }
}