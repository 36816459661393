import IMask from 'imask';

const Mask = () =>{



    function mask_tel(mask){
        IMask(
            mask,
            {
              mask: '+{55} 00 00000-0000'
            }
          )
    }

    const MaskTel = document.querySelectorAll("[js-mask-tel]");
    MaskTel?.forEach((maski)=>{
        mask_tel(maski);
    })

}

export default Mask;