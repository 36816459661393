

const ModalContato = () =>{

    // Quando o botão do WhatsApp for clicado, abre a modal em vez de ir direto
    const btWhatssap = document.querySelectorAll("[js-whatsapp-modal]");
    btWhatssap?.forEach((bt)=>{
        bt.addEventListener("click", (e)=>{
            e.preventDefault();
            document.getElementById("modal").style.display = "flex";
        })
    })

    //** Fechar Modal **/
    const btFecharModal = document.querySelectorAll("[js-fechar-modal]");
    btFecharModal?.forEach((bt)=>{
        bt.addEventListener("click", (e)=>{
            const idModal = e.target.getAttribute('js-fechar-modal');
            document.querySelector(`[js-modal="${idModal}"]`).style.display = "none";
        })
    });


    const submitParaWhatssap = document.querySelectorAll("[js-submit-whatssap]");
    submitParaWhatssap.forEach((item)=>{
        item.addEventListener("click", (e)=>{
            disabledBtn()

            let nome = document.getElementById("fale-whatssap-nome").value;
            let email = document.getElementById("fale-whatssap-email").value;
            let telefone = document.getElementById("fale-whatssap-telefone").value;

            if (nome && telefone) {
                let mensagem = `Olá, meu nome é ${nome} e tenho dúvidas sobre o Maska`;
                let numeroWhatsApp = "+5521979485552"; // Substitua pelo seu número no formato internacional
                let url = `https://api.whatsapp.com/send?phone=${numeroWhatsApp}&text=${encodeURIComponent(mensagem)}`;

                const form_data = {
                    name: nome,
                    phone: telefone,
                    email: email,
                }

                const url_form = "/criar-lead/"
                axios.post(url_form, form_data)
                    .then((response) => showMessage(response, url))
                    .catch((response) => showMessage(response));

                //window.location.href = url;
            } else {
                alert("Por favor, preencha pelo menos o nome e o telefone.");
            }
        })
    })



    function showMessage(response, url = undefined) {
        let message = response.data ? response.data.message : response.message
        if(!message){
            message = 'Resposta enviada com sucesso'
        }

        GLOBAL.showToastify(message)
        //contatoFormSubmit.reset()
        setTimeout(()=>{

            enabledBtn();
            if(url != undefined){
                window.open(url, '_blank');
                document.querySelector(`[js-modal="1"]`).style.display = "none";
            }
        }, 2000)
    }

    const btnSubmit = document.querySelector('[js-submit-whatssap]');

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.style.opacity = "0.5"
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.style.opacity = "1.0"
    }


    

}

export default ModalContato;